import { Injectable } from '@angular/core';
import Swal, { SweetAlertIcon, SweetAlertResult } from 'sweetalert2';
import toastr from 'toastr';

export type TypeValue = string | HTMLElement | JQuery<HTMLElement>;

// export interface AlertColumns {
// 	headerName: string;
// 	name: string;
// 	stylesCell?: string;
// 	stylesHeader?: string;
// }

// export interface AlertOptions {
// 	title?: string;
// 	icon?: SweetAlertIcon;
// 	width?: number | string;
// 	position?: SweetAlertPosition;
// }

@Injectable({
	providedIn: 'root',
})
export class MessageUtilService {
	private _toastrOptions: ToastrOptions;

	constructor() {
		this._toastrOptions = {
			positionClass: 'toast-bottom-right',
			closeButton: true,
			progressBar: true,
			newestOnTop: true,
		};
	}

	/**
	 * Toast success
	 * @param message
	 * @param title
	 * @returns JQuery<HTMLElement>
	 */
	success(message: string, title?: string): JQuery<HTMLElement> {
		return toastr.success(message, title ? `<h5 class='text-white'> ${title} </h5>` : undefined, this._toastrOptions);
	}

	/**
	 * Toast error
	 * @param message
	 * @param title
	 * @returns JQuery<HTMLElement>
	 */
	error(message: string, title?: string): JQuery<HTMLElement> {
		return toastr.error(message, title ? `<h5 class='text-white'> ${title} </h5>` : undefined, this._toastrOptions);
	}

	/**
	 * Toast warning
	 * @param message
	 * @param title
	 * @returns JQuery<HTMLElement>
	 */
	warning(message: string, title?: string): JQuery<HTMLElement> {
		return toastr.warning(message, title ? `<h5 class='text-white'> ${title} </h5>` : undefined, this._toastrOptions);
	}

	/**
	 * Toast info
	 * @param message
	 * @param title
	 * @returns JQuery<HTMLElement>
	 */
	info(message: string, title?: string): JQuery<HTMLElement> {
		return toastr.info(message, title ? `<h5 class='text-white'> ${title} </h5>` : undefined, this._toastrOptions);
	}

	/**
	 * Muestra una alerta con una tabla de datos
	 * @param columns columnas de la tabla `tHead`
	 * @param rows datos de la tabla `tBody`
	 * @param options
	 * @returns
	 */
	// alertTable(columns: AlertColumns[], rows: any[], options?: AlertOptions) {
	// 	if (columns === undefined || columns === null) {
	// 		throw new Error('El valor de la variable columns no existe.');
	// 	}

	// 	if (rows === undefined || rows === null) {
	// 		throw new Error('El valor de la variable rows no existe.');
	// 	}

	// 	try {
	// 		let tHead = '';
	// 		let tBody = '';

	// 		for (let i = 0; i < columns.length; i++) {
	// 			const column = columns[i];
	// 			tHead += `<td style="${column.stylesHeader ? column.stylesHeader : ''}">${column.headerName}</td>`;
	// 		}

	// 		for (let i = 0; i < rows.length; i++) {
	// 			const row = rows[i];

	// 			tBody += '<tr>';
	// 			for (let f = 0; f < columns.length; f++) {
	// 				const column = columns[f];
	// 				tBody += `<td style="${column.stylesCell ? column.stylesCell : ''}">${row[column.name]}</td>`;
	// 			}
	// 			tBody += '</tr>';
	// 		}

	// 		const html = `
	// 		<div class="table-responsive" style="height: 230px;">
	// 		  <table class="table table-bordered table-hover">
	// 			<thead style="position: sticky;top: 0;background: white;">
	// 			  <tr>
	// 				  ${tHead}
	// 			  </tr>
	// 			</thead>
	// 			<tbody>
	// 				${tBody}
	// 			</tbody>
	// 		  </table>
	// 		</div>
	// 		`;

	// 		return Swal.fire({
	// 			title: options?.title,
	// 			html,
	// 			icon: options?.icon,
	// 			position: options?.position,
	// 			width: options?.width,
	// 		});
	// 	} catch {
	// 		throw new Error('Ocurrio un error MessageUtilService metodo alertTable');
	// 	}
	// }

	// alert(options: SweetAlertOptions): Promise<SweetAlertResult<boolean>> {
	// 	return Swal.fire(options);
	// }

	/**
	 * Alert success
	 * @param message
	 * @param title
	 * @returns Promise<SweetAlertResult<boolean>>
	 */
	alertSuccess(message: TypeValue, title?: TypeValue): Promise<SweetAlertResult<boolean>> {
		return Swal.fire({
			icon: 'success',
			title: title ? title : undefined,
			html: message,
		});
	}

	/**
	 * Alert error
	 * @param message
	 * @param title
	 * @returns Promise<SweetAlertResult<boolean>>
	 */
	alertError(message: TypeValue, title?: TypeValue): Promise<SweetAlertResult<boolean>> {
		return Swal.fire({
			icon: 'error',
			title: title ? title : undefined,
			html: message,
		});
	}

	/**
	 * Alert warning
	 * @param message
	 * @param title
	 * @returns Promise<SweetAlertResult<boolean>>
	 */
	alertWarning(message: TypeValue, title?: TypeValue): Promise<SweetAlertResult<boolean>> {
		return Swal.fire({
			icon: 'warning',
			title: title ? title : undefined,
			html: message,
		});
	}

	/**
	 * Alert info
	 * @param message
	 * @param title
	 * @returns Promise<SweetAlertResult<boolean>>
	 */
	alertInfo(message: TypeValue, title?: TypeValue): Promise<SweetAlertResult<boolean>> {
		return Swal.fire({
			icon: 'info',
			title: title ? title : undefined,
			html: message,
		});
	}

	/**
	 * Aler question
	 * @param message
	 * @param title
	 * @param icon
	 * @returns Promise<SweetAlertResult<boolean>>
	 */
	alertQuestion(message: TypeValue, title?: TypeValue, icon: SweetAlertIcon = 'question'): Promise<SweetAlertResult<boolean>> {
		return Swal.fire({
			icon,
			title: title ? title : undefined,
			html: message,
			showCancelButton: true,
			confirmButtonColor: '#08976d',
			cancelButtonColor: '#fd1361',
			confirmButtonText: 'Sí',
			cancelButtonText: 'No',
			focusCancel: true,
			// customClass: {
			// 	confirmButton: 'btn btn-success',
			// 	cancelButton: 'btn btn-danger'
			// },
			// buttonsStyling: false
		});
	}
	/*----------------------------- */

	/**@deprecated  usar el método error */
	// getMessageError(codigo: string, element = '', message?: string) {
	// 	// tslint:disable: indent
	// 	switch (codigo) {
	// 		case 'A001':
	// 			this.message = `El campo ${element}  es requerido`;
	// 			break;
	// 		case 'A002':
	// 			this.message = `El campo ${element} no cumple con el formato establecido`;
	// 			break;
	// 		case 'A003':
	// 			this.message = `La fecha ingresada no puede ser superior a la fecha del dí­a actual`;
	// 			break;
	// 		case 'A004':
	// 			this.message = `La fecha del documento no puede ser menor a la fecha del ultimo documento ingresado`;
	// 			break;
	// 		case '000046':
	// 			this.message = `${message}`;
	// 			break;
	// 		case 'A005':
	// 			this.message = `No ha ingresado la serie`;
	// 			break;
	// 		case 'A999':
	// 			this.message = `${element}`;
	// 			element = 'Error';
	// 			// return toastr.error(this.message);
	// 			break;
	// 		case '500':
	// 			this.message = `Por favor comuniquese con el soporte del Sistema`;
	// 			break;
	// 		default:
	// 			this.message = element;
	// 			break;
	// 	}
	// 	toastr.options = {
	// 		positionClass: 'toast-bottom-right',
	// 		closeButton: true,
	// 		progressBar: true,
	// 		newestOnTop: true,
	// 	};
	// 	toastr['error'](this.message, `<h5 class='text-white'> ${element} </h5>`);
	// 	return toastr;
	// }

	/**@deprecated usar el método warning */
	// getAlertWarning(mensaje: string) {
	// 	toastr.options = {
	// 		positionClass: 'toast-bottom-right',
	// 		closeButton: true,
	// 		progressBar: true,
	// 		newestOnTop: true,
	// 	};
	// 	toastr['warning'](mensaje);
	// 	return toastr;
	// }

	/**@deprecated usar el método error alertWarning */
	// getMessageWarning(mensaje: string) {
	// 	const swalWithBootstrapButtons = Swal.mixin({
	// 		customClass: {
	// 			confirmButton: 'btn btn-success',
	// 			cancelButton: 'btn btn-danger',
	// 		},
	// 		buttonsStyling: false,
	// 	});
	// 	return swalWithBootstrapButtons.fire({
	// 		title: 'Eliminar',
	// 		html: mensaje,
	// 		icon: 'warning',
	// 		showCancelButton: true,
	// 		confirmButtonText: 'Aceptar',
	// 		cancelButtonText: 'Cancelar',
	// 		reverseButtons: false,
	// 	});
	// }

	/**@deprecated usar el método alertQuestion */
	// getMessageQuestion(mensaje: string) {
	// 	return Swal.fire({
	// 		icon: 'question',
	// 		html: mensaje,
	// 		showCancelButton: true,
	// 		confirmButtonColor: '#3085d6',
	// 		cancelButtonColor: '#d33',
	// 		confirmButtonText: 'Sí',
	// 		cancelButtonText: 'No',
	// 	});
	// }

	/**@deprecated usar el método alertSucces */
	// getMessageSuccess(titulo: string, mensaje: string) {
	// 	return Swal.fire({
	// 		icon: 'success',
	// 		title: titulo,
	// 		text: mensaje,
	// 	});
	// }

	/**@deprecated usar el método alertError */
	// getFailMessage(mensaje: string) {
	// 	return Swal.fire({
	// 		icon: 'error',
	// 		title: 'Error',
	// 		text: mensaje,
	// 	});
	// }

	/**@deprecated usar el método alertSuccess */
	// getMessageSuccessDelete(mensaje: string) {
	// 	return Swal.fire({
	// 		icon: 'success',
	// 		title: 'Eliminación exitosa',
	// 		text: mensaje,
	// 	});
	// }

	/**@deprecated usar el método alertInfo */
	// getMessageInfo(mensaje: string) {
	// 	return Swal.fire({
	// 		icon: 'info',
	// 		title: 'Información',
	// 		text: mensaje,
	// 	});
	// }

	/**@deprecated usar el método alertSuccess */
	// getMessageSuccessTransmit(mensaje: string) {
	// 	return Swal.fire({
	// 		icon: 'success',
	// 		title: 'Transmisión exitosa',
	// 		text: mensaje,
	// 	});
	// }

	/**@deprecated */
	// getNotResultSpanish() {
	// 	return 'No existen resultados';
	// }

	/**@deprecated usar el método alertTable */
	// loadTable() {
	// 	return `<span class="ag-overlay-loading-top d-flex align-items-center
	// 	border-0 bg-white shadow p-4 text-info" style="height: 20px;">
	// 	<img src="assets/media-demo/loading.gif"
	// 	height="20" width="25" class="mr-2"><strong>Cargando registros</strong></span>`;
	// }

	/**@deprecated usar el método alertTable */
	// loadTableIsBlank() {
	// 	return `<span class="ag-overlay-loading-center border-0 bg-transparent">
	// 	No existen Documentos Relacionados ...</span>`;
	// }

	/**@deprecated */
	// loadClassDisplayFlex(justifyContent: string, alignItems: string) {
	// 	return `d-flex justify-content-${justifyContent} align-items-${alignItems}`;
	// }
}
