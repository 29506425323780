export class MenuConfig {
	// VENTAS
	public defaults: any = {
		header: {
			self: {},
			items: [
				{
					title: 'Tablero Principal',
					root: true,
					alignment: 'left',
					bullet: 'dot',
					icon: 'flaticon2-chart',
					page: '/ventas/dashboard', // colocar ruta del dashboard
				},
				{
					title: 'Favoritos',
					root: true,
					alignment: 'left',
					bullet: 'dot',
					icon: 'flaticon-star',
				},
				{
					title: 'Gestión',
					root: true,
					alignment: 'left',
					bullet: 'dot',
					icon: 'flaticon-doc',
					submenu: [
						{
							title: 'Facturas',
							page: '/ventas/gestion/comprobantes/01',
							bullet: 'dot',
							icon: 'flaticon-interface-7',
						},
						{
							title: 'Boletas',
							page: '/ventas/gestion/comprobantes/03',
							bullet: 'dot',
							icon: 'flaticon-web',
						},
						{
							title: 'Notas de Crédito',
							page: '/ventas/gestion/comprobantes/07',
							bullet: 'dot',
							icon: 'flaticon-web',
						},
						{
							title: 'Notas de Débito',
							page: '/ventas/gestion/comprobantes/08',
							bullet: 'dot',
							icon: 'flaticon-web',
						},
						{
							title: 'Transmisión Electrónica',
							page: '/ventas/gestion/transmision/comprobante',
							bullet: 'dot',
							icon: 'fas fa-paper-plane',
						},
					],
				},
				{
					title: 'Control',
					root: true,
					alignment: 'left',
					bullet: 'dot',
					icon: 'flaticon-squares',
					submenu: [
						{
							title: 'Reporte de Ventas',
							page: 'error/403',
							bullet: 'dot',
							icon: 'flaticon-interface-7',
						},
						{
							title: 'Reporte Sunat',
							page: 'error/403',
							bullet: 'dot',
							icon: 'flaticon-web',
						},
					],
				},
				{
					title: 'Reportes',
					root: true,
					alignment: 'left',
					bullet: 'dot',
					icon: 'flaticon2-poll-symbol',
					submenu: [
						{
							title: 'Reporte de Ventas',
							page: 'error/403',
							bullet: 'dot',
							icon: 'flaticon-interface-7',
						},
						{
							title: 'Reporte Sunat',
							page: 'error/403',
							bullet: 'dot',
							icon: 'flaticon-web',
						},
					],
				},
				{
					title: 'Herramientas',
					root: true,
					alignment: 'left',
					bullet: 'dot',
					icon: 'flaticon2-settings',
					submenu: [
						{
							title: 'Cierre de Período',
							page: '/ventas/herramientas/cierre-periodo',
							bullet: 'dot',
							icon: 'flaticon-interface-7',
						},
						{
							title: 'Copiar Documentos',
							page: '/ventas/herramientas/copia',
							bullet: 'dot',
							icon: 'flaticon-web',
						},
					],
				},
			],
		},
	};

	public deposito: any = {
		header: {
			self: {},
			items: [
				{
					title: 'Tablero Principal',
					root: true,
					alignment: 'left',
					bullet: 'dot',
					icon: 'flaticon2-chart',
					page: '/deposito/dashboard', // colocar ruta del dashboard
				},
				{
					title: 'Favoritos',
					root: true,
					alignment: 'left',
					bullet: 'dot',
					icon: 'flaticon-star',
					/* page: '/maestros/gestion'   colocar ruta del dashboard*/
				},
				{
					title: 'Gestión',
					root: true,
					alignment: 'left',
					bullet: 'dot',
					icon: 'flaticon-doc',
					submenu: [
						{
							title: 'Conceptos',
							page: '/deposito/concepto',
							bullet: 'dot',
							icon: 'flaticon-web',
						},
						{
							title: 'Divisiones',
							page: '/deposito/division',
							bullet: 'dot',
							icon: 'flaticon-web',
						},
						{
							title: 'Series',
							page: '/deposito/proveedor',
							bullet: 'dot',
							icon: 'flaticon-web',
						},
						{
							title: 'Proveedores',
							page: '/deposito/proveedor',
							bullet: 'dot',
							icon: 'flaticon-web',
						},
						{
							title: 'Clientes',
							page: '/deposito/cliente',
							bullet: 'dot',
							icon: 'flaticon-web',
						},
						{
							title: 'Proyectos',
							page: '/deposito/proveedor',
							bullet: 'dot',
							icon: 'flaticon-web',
						},
					],
				},
				{
					title: 'Control',
					root: true,
					alignment: 'left',
					bullet: 'dot',
					icon: 'flaticon-squares',
					submenu: [],
				},
				{
					title: 'Reportes',
					root: true,
					alignment: 'left',
					bullet: 'dot',
					icon: 'flaticon2-poll-symbol',
					submenu: [],
				},
				{
					title: 'Herramientas',
					root: true,
					alignment: 'left',
					bullet: 'dot',
					icon: 'flaticon2-settings',
					submenu: [],
				},
			],
		},
	};

	public get configs(): any {
		return this.defaults;
	}

	public get depositoBar(): any {
		return this.deposito;
	}
}
