import { KtDialogService, StickyDirective } from '@core/_base/layout';
// Angular
import {
	AfterViewInit,
	Component,
	ElementRef,
	HostBinding,
	HostListener,
	Inject,
	Input,
	OnDestroy,
	OnInit,
	PLATFORM_ID,
	ViewChild,
	ChangeDetectionStrategy,
	ChangeDetectorRef,
	ContentChildren,
	QueryList,
	ViewEncapsulation,
} from '@angular/core';
// RXJS
import { Observable, Subscription } from 'rxjs';
import { AfterContentInit } from '@angular/core';
import { KtPortletTools } from './ktPortletTools.directive';
import { KtPortletTitle } from './ktPortletTitle.directive';
import { KtPortletIcon } from './ktPortletIcon.directive';
import { KtPortletSticky } from './ktPortletSticky.directive';

@Component({
	selector: 'kt-portlet-header',
	styleUrls: ['portlet-header.component.scss'],
	template: `
		<div class="kt-portlet-header-content">
			<div class="col pl-0 kt-portlet__head-label" *ngIf="noTitle">
				<span class="kt-portlet__head-icon" *ngIf="!icon && _portletIconChildren?.length">
					<ng-content select="[ktPortletIcon]"></ng-content>
				</span>

				<span class="kt-portlet__head-icon" *ngIf="icon">
					<i [ngClass]="icon"></i>
				</span>

				<ng-container *ngIf="!title && _portletTitleChildren?.length">
					<ng-content select="[ktPortletTitle]"></ng-content>
				</ng-container>

				<h3 *ngIf="title" class="kt-portlet__head-title" [innerHTML]="title"></h3>
			</div>

			<div class="col pr-0 justify-content-end kt-portlet__head-toolbar" *ngIf="_portletToolsChildren?.length">
				<ng-content select="[ktPortletTools]"></ng-content>
			</div>
		</div>

		<ng-container *ngIf="_portletStickyChildren?.length">
			<div class="kt-portlet-header-sticky">
				<ng-content select="[ktPortletSticky]"></ng-content>
			</div>
		</ng-container>
	`,
	host: {
		class: 'kt-portlet-header',
	},
	encapsulation: ViewEncapsulation.None,
	changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PortletHeaderComponent implements OnInit, AfterViewInit, AfterContentInit, OnDestroy {
	// Public properties
	@ContentChildren(KtPortletTools, { descendants: true })
	_portletToolsChildren!: QueryList<KtPortletTools>;
	@ContentChildren(KtPortletTitle, { descendants: true })
	_portletTitleChildren!: QueryList<KtPortletTitle>;
	@ContentChildren(KtPortletIcon, { descendants: true })
	_portletIconChildren!: QueryList<KtPortletIcon>;
	@ContentChildren(KtPortletSticky, { descendants: true })
	_portletStickyChildren!: QueryList<KtPortletSticky>;
	/**
	 * append html class to the portlet header
	 * @deprecated
	 */
	@Input() customClass!: string;
	/**
	 * a simple title text
	 * @type {string}
	 * @default null
	 */
	@Input() title!: string;
	/**
	 * icon name to be added to the i tag
	 * @type {string}
	 * @default null
	 */
	@Input() icon!: string;
	/**
	 * remove title container
	 * @type {boolean}
	 * @default false
	 */
	@Input() noTitle = true;
	/**
	 * enable sticky portlet header
	 * @type {boolean}
	 * @default false
	 */
	@Input() sticky = false;
	/**
	 * enable loading to display
	 * @type {Observable<boolean>}
	 * @default mull
	 */
	@Input() viewLoading$!: Observable<boolean>;
	viewLoading = false;
	/**
	 * custom class
	 * @type {string}
	 * @default null
	 */
	@Input() class!: string;

	// @HostBinding('class') classList = 'kt-portlet__head px-3 py-2';
	@HostBinding('attr.ktSticky') stickyDirective: StickyDirective;

	// @ViewChild('refIcon', { static: true }) refIcon: ElementRef;
	// hideIcon = false;

	// @ViewChild('refTools', { static: true }) refTools: ElementRef;
	// hideTools = false;

	private lastScrollTop = 0;
	private subscriptions: Subscription[] = [];
	private isScrollDown = false;

	constructor(private el: ElementRef, @Inject(PLATFORM_ID) private platformId: string, private ktDialogService: KtDialogService, private changeRef: ChangeDetectorRef) {
		this.stickyDirective = new StickyDirective(this.el, this.platformId);
	}

	@HostListener('window:resize', [])
	onResize() {
		this.updateStickyPosition();
	}

	@HostListener('window:scroll', [])
	onScroll() {
		this.updateStickyPosition();
		const st = window.pageYOffset || document.documentElement.scrollTop;
		this.isScrollDown = st > this.lastScrollTop;
		this.lastScrollTop = st <= 0 ? 0 : st;
	}

	updateStickyPosition() {
		if (this.sticky) {
			Promise.resolve(null).then(() => {
				// get boundary top margin for sticky header
				const headerElement = document.querySelector('.kt-header') as HTMLElement;
				const subheaderElement = document.querySelector('.kt-subheader') as HTMLElement;
				const headerMobileElement = document.querySelector('.kt-header-mobile') as HTMLElement;

				let height = 0;

				if (headerElement != null) {
					// mobile header
					if (window.getComputedStyle(headerElement).height === '0px') {
						height += headerMobileElement.offsetHeight;
					} else {
						// desktop header
						if (document.body.classList.contains('kt-header--minimize-topbar')) {
							// hardcoded minimized header height
							height = 60;
						} else {
							// normal fixed header
							if (document.body.classList.contains('kt-header--fixed')) {
								height += headerElement.offsetHeight;
							}
							if (document.body.classList.contains('kt-subheader--fixed')) {
								height += subheaderElement.offsetHeight;
							}
						}
					}
				}

				this.stickyDirective.marginTop = height;
			});
		}
	}

	/**
	 * @ Lifecycle sequences => https://angular.io/guide/lifecycle-hooks
	 */

	/**
	 * On init
	 */
	ngOnInit() {
		if (this.sticky) {
			this.stickyDirective.ngOnInit();
		}

		// if (this.class) {
		// 	this.classList += ' ' + this.class;
		// }
	}

	ngAfterViewInit(): void {
		// // append custom class
		// this.classList += this.customClass ? ' ' + this.customClass : '';

		// // hide icon's parent node if no icon provided
		// this.hideIcon = this.refIcon.nativeElement.children.length === 0;

		// // hide tools' parent node if no tools template is provided
		// this.hideTools = this.refTools.nativeElement.children.length === 0;

		if (this.sticky) {
			this.updateStickyPosition();
			this.stickyDirective.ngAfterViewInit();
		}

		// // initialize loading dialog
		// if (this.viewLoading$) {
		//   const loadingSubscription = this.viewLoading$.subscribe(res => this.toggleLoading(res));
		//   this.subscriptions.push(loadingSubscription);
		// }
	}

	ngAfterContentInit(): void {
		// append custom class
		// this.classList += this.customClass ? ' ' + this.customClass : '';

		// hide icon's parent node if no icon provided
		// this.hideIcon = this.refIcon.nativeElement.children.length > 0;

		// hide tools' parent node if no tools template is provided
		// this.hideTools = this.refTools.nativeElement.children.length === 0;

		// if (this.sticky) {
		//   this.updateStickyPosition();
		//   this.stickyDirective.ngAfterViewInit();
		// }

		// initialize loading dialog
		if (this.viewLoading$) {
			const loadingSubscription = this.viewLoading$.subscribe((res) => this.toggleLoading(res));
			this.subscriptions.push(loadingSubscription);
		}

		this.changeRef.markForCheck();
	}

	toggleLoading(_incomingValue: boolean) {
		this.viewLoading = _incomingValue;
		if (_incomingValue && !this.ktDialogService.checkIsShown()) {
			this.ktDialogService.show();
		}

		if (!this.viewLoading && this.ktDialogService.checkIsShown()) {
			this.ktDialogService.hide();
		}
	}

	ngOnDestroy(): void {
		this.subscriptions.forEach((sb) => sb.unsubscribe());
		if (this.sticky) {
			this.stickyDirective.ngOnDestroy();
		}
	}
}
