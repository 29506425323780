import { Component, ChangeDetectionStrategy, OnInit, OnDestroy } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { Subscription } from 'rxjs';
// language list
import { locale as enLang } from '@core/_config/i18n/en';
import { locale as chLang } from '@core/_config/i18n/ch';
import { locale as esLang } from '@core/_config/i18n/es';
import { locale as jpLang } from '@core/_config/i18n/jp';
import { locale as deLang } from '@core/_config/i18n/de';
import { locale as frLang } from '@core/_config/i18n/fr';
import { TranslationService, LayoutConfigService, SplashScreenService } from '@core/_base/layout';
import { Router, NavigationEnd, ActivatedRoute } from '@angular/router';

import { LayoutConfig } from '@core/_config/layout.config';
import { filter, map } from 'rxjs/operators';

@Component({
	selector: 'body[kt-root]',
	templateUrl: './app.component.html',
	styleUrls: ['./app.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AppComponent implements OnInit, OnDestroy {
	isLoader = true;
	private _subscription = new Subscription();
	// eslint-disable-next-line @typescript-eslint/naming-convention
	private _unsubscrite: Subscription[] = [];

	constructor(
		private titleService: Title,
		private activatedRoute: ActivatedRoute,
		private translationService: TranslationService,
		private router: Router,
		private layoutConfigService: LayoutConfigService,
		private splashScreenService: SplashScreenService
	) {
		this.translationService.loadTranslations(enLang, chLang, esLang, jpLang, deLang, frLang);

		this._subscription.add(
			this.router.events
				.pipe(
					filter((event) => event instanceof NavigationEnd),
					map(() => {
						let child = this.activatedRoute.firstChild;
						while (child) {
							if (child.firstChild) {
								child = child.firstChild;
							} else if (child.snapshot.data && child.snapshot.data['titulo']) {
								return child.snapshot.data['titulo'] as string;
							} else {
								return null;
							}
						}
						return null;
					})
				)
				.subscribe((data) => {
					if (data) {
						this.titleService.setTitle(`${String(data)}`);
					}
				})
		);
	}

	ngOnInit(): void {
		this.layoutConfigService.loadConfigs(new LayoutConfig().configs);
		this.isLoader = Boolean(this.layoutConfigService.getConfig('loader.enabled'));

		const routerSubscription = this.router.events.subscribe((event) => {
			if (event instanceof NavigationEnd) {
				if (event.url.startsWith('/deposito/ingr-recep-mercancia/regedit-ingr-recep/')) {
					document.body.classList.add('kt-page--loaded');
					window.scrollTo(0, 0);
				} else {
					this.splashScreenService.hide();
					document.body.classList.add('kt-page--loaded');
					window.scrollTo(0, 0);
				}
			}
		});
		this._unsubscrite.push(routerSubscription);

		// this._subscription.add(
		// 	this.router.events.subscribe((event) => {
		// 		if (event instanceof NavigationEnd) {
		// 			setTimeout(() => {
		// 				this.splashScreenService.hide();
		// 				document.body.classList.add('kt-page--loaded');
		// 				window.scrollTo(0, 0);
		// 			}, 2000);
		// 		}
		// 	})
		// );
	}

	ngOnDestroy(): void {
		this._subscription.unsubscribe();
		this._unsubscrite.forEach((sb) => sb.unsubscribe());
	}
}
