export const environmentBase = {
	authTokenKey: 'authce9d77b308c149d5992a80073637e4d5',
	idToken: 'TokenID',
	su: 'su',
	expires_in: 'expires_in',
	sessionId: 'SessionID',
	refreshToken: 'TokenRefresh',
	maestroSyncToken: 'TokenMaestroSync',
	username: 'username',
	notifications: 'notifications',

	PATH_BASE: 'plantilla',

	COD_SISTEMA: 'SDE',

	URL_LOGIN: 'https://dev-login.sintad.net.pe/#/auth/login',

	URL_HOME: 'https://dev-login.sintad.net.pe/#/home',

	HOST_SECURITY: 'https://api-cluster.sumax.pe/dev/sumax-erp-backend-security/api/security',

	HOST_CONFIG: 'https://api-cluster.sumax.pe/dev/sumax-erp-backend-config/api',

	HOST_MAESTROS: 'https://api-cluster.sumax.pe/dev/sumax-erp-backend-maestros/api',

	HOST_MAESTROS_SYNC: 'https://api-cluster-sistemas-internos.sumax.pe/dev/sumax-erp-backend-maestros-sync/api',

	HOST_EMISION_ELECTRONICA: 'https://api-cluster.sumax.pe/dev/sumax-erp-backend-emision-electronica/api',

	HOST_DEPOSITO: `https://api-cluster.sumax.pe/dev/sumax-erp-backend-deposito/api`,
	//HOST_DEPOSITO: 'http://localhost:8098/sumax-erp-backend-deposito/api',

	HOST_MANIFIESTO: `https://api-cluster-sistemas-internos.sumax.pe/dev/sumax-erp-backend-manifiesto/api`,

	url: 'http://www.aduanet.gob.pe/cl-ad-itconsmanifiesto/manifiestoITS01Alias?accion=consultarxNumeroManifiesto&CMc1_Anno=', //NOSONAR

	// URL_DEPOSITO: 'https://dev-deposito.sintad.net.pe',
	URL_DEPOSITO: 'http://localhost:4200',

	URL_DMS: 'https://dev-dms.sintad.net.pe',
	//URL_DMS: 'http://localhost:4200',

	searchTbEntidadDto: {
		idTipoDocumento: 'idTipoDocumento',
		nroDocumento: 'nroDocumento',
	},

	HOST_ROOT: '.sintad.net.pe',
};

export function mergeEnviroments<T>(environmentBase: T, newEnviroment: Partial<T>): T {
	return {
		...environmentBase,
		...newEnviroment,
	};
}
