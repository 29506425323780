// Angular
import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AuthGuard } from '@core/security/guards/auth.guard';
import { BaseComponent } from '@theme/base/base.component';
import { ErrorPageComponent } from '@theme/error-page/error-page.component';
import { DashboardModule } from 'app/modules/dashboard/dashboard.module';
import { IngrVehiculoAlmacenModule } from 'app/modules/gestion/ingr-vehiculo-almacen/ingr-vehiculo-almacen.module';
import { IngrRecepMercanciaModule } from './modules/gestion/ingr-recep-mercancia/ingr-recep-mercancia.module';
import { ActaInventarioModule } from './modules/gestion/acta-inventario/acta-inventario.module';
import { DesdobleVolanteModule } from './modules/gestion/desdoble-volante/desdoble-volante.module';
import { PermisoSalidaAlmacenModule } from './modules/gestion/permiso-salida-almacen/permiso-salida-almacen.module';
import { SalidaAlmacenModule } from './modules/gestion/salida-almacen/salida-almacen.module';

// Components

const routes: Routes = [
	{
		path: 'deposito',
		component: BaseComponent,
		canActivate: [AuthGuard],
		children: [
			{
				path: 'dashboard',
				data: { titulo: 'Dashboard | Depósito' },
				loadChildren: (): Promise<typeof DashboardModule> => import('app/modules/dashboard/dashboard.module').then((m) => m.DashboardModule),
			},
			{
				path: 'ingr-vehiculo-almacen',
				data: { titulo: 'Ingreso vehículo almacén | Depósito' },
				loadChildren: (): Promise<typeof IngrVehiculoAlmacenModule> => import('app/modules/gestion/ingr-vehiculo-almacen/ingr-vehiculo-almacen.module').then((m) => m.IngrVehiculoAlmacenModule),
			},
			{
				path: 'ingr-recep-mercancia',
				data: { titulo: 'Ingreso recepción mercancías | Depósito' },
				loadChildren: (): Promise<typeof IngrRecepMercanciaModule> => import('app/modules/gestion/ingr-recep-mercancia/ingr-recep-mercancia.module').then((m) => m.IngrRecepMercanciaModule),
			},
			{
				path: 'ingr-acta-inventario',
				data: { titulo: 'Acta inventario | Depósito' },
				loadChildren: (): Promise<typeof ActaInventarioModule> => import('app/modules/gestion/acta-inventario/acta-inventario.module').then((m) => m.ActaInventarioModule),
			},
			{
				path: 'desdoble-volante',
				data: { titulo: 'Desdoble volante | Depósito' },
				loadChildren: (): Promise<typeof DesdobleVolanteModule> => import('app/modules/gestion/desdoble-volante/desdoble-volante.module').then((m) => m.DesdobleVolanteModule),
			},
			{
				path: 'permiso-salida-almacen',
				data: { titulo: 'Permiso salida almacén | Depósito' },
				loadChildren: (): Promise<typeof PermisoSalidaAlmacenModule> => import('app/modules/gestion/permiso-salida-almacen/permiso-salida-almacen.module').then((m) => m.PermisoSalidaAlmacenModule),
			},
			{
				path: 'salida-almacen',
				data: { titulo: 'Salida almacén | Depósito' },
				loadChildren: (): Promise<typeof SalidaAlmacenModule> => import('app/modules/gestion/salida-almacen/salida-almacen.module').then((m) => m.SalidaAlmacenModule),
			},
			{
				path: 'error/:type',
				component: ErrorPageComponent,
			},
			{ path: '', redirectTo: 'dashboard', pathMatch: 'full' },
			{ path: '**', redirectTo: '/error/403', pathMatch: 'full' },
		],
	},
	{
		path: 'iframe',
		children: [
			{
				path: '',
				// eslint-disable-next-line @typescript-eslint/explicit-function-return-type
				loadChildren: () => import('app/modules/iframe/iframe.module').then((m) => m.IframeModule),
			},
		],
	},
	{
		path: 'error/403',
		component: ErrorPageComponent,
		data: {
			type: 'error-v6',
			code: 403,
			title: 'Prohibido pasar',
			desc: 'Tranquilo bro, aún no desarrollamos esto...',
		},
	},
	{
		path: 'error/acceso-denegado',
		component: ErrorPageComponent,
		data: {
			type: 'error-v6',
			code: 403,
			title: 'Prohibido pasar',
			desc: 'No tiene permisos para ver esta página',
		},
	},
	{ path: '**', redirectTo: 'deposito', pathMatch: 'full' },
];

@NgModule({
	imports: [RouterModule.forRoot(routes, { initialNavigation: 'disabled', relativeLinkResolution: 'legacy' })],
	exports: [RouterModule],
})
export class AppRoutingModule {}
